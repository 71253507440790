
import { Component, Vue } from 'vue-property-decorator';
import { changeLoading } from '@/util/decorators';
import storage from 'baimahu/storage';
@Component
export default class AddCoupon extends Vue {
  is_loading: boolean = false;
  type: number = 1;
  range: number = 1;
  public grant_type: number = 1;
  user_type: number = 1; //用户类型
  radioChange() {
    if (this.grant_type == 3) {
      this.user_type = 2;
    }
    if (this.grant_type == 5) {
      this.user_type = 1;
    }
  }

  userTypeRadioProps(key: number) {
    if(this.grant_type==5&&key!==1){
      return {disabled:true}
    }
    switch (key) {
      case 1:
        return { disabled:this.grant_type==3}
      case 2:
        return {};
      case 3:
      return { disabled:this.grant_type==3}
      case 4:
        return {};
      default:
        return {} as never;
    }
  }
  addCouponSecond() {
    storage.setItem('coupon_type', {
      type: this.type,
      range: this.range,
      grant_type: this.grant_type,
      user_type: this.user_type,
    });
    this.$router.push('/marketing/add-coupon-second');
  }
  getCouponList() {
    if (storage.getItem('coupon_type') != null) {
      this.type = storage.getItem('coupon_type').type || 1;
      this.range = storage.getItem('coupon_type').range || 1;
      this.grant_type = storage.getItem('coupon_type').grant_type || 1;
      this.user_type = storage.getItem('coupon_type').user_type || 1;
    }
  }
  async created() {
    await this.getCouponList();
  }
}
